import request from "@/utils/request";
function getUrl(url) {
    return "/operate/" + url;
}

export default {
    updateUcHandleLog(data){
        return request({
            url: getUrl("handleLog/updateUcHandleLog"),
            method: "post",
            data,
        })
    },
    deleteUcHandleLog(data){
        return request({
            url: getUrl("handleLog/deleteUcHandleLog"),
            method: "post",
            data,
        })
    },
    getUcHandleLogVo(data){
        return request({
            url: getUrl("handleLog/getUcHandleLogVo"),
            method: "post",
            data,
        })
    },
    getUcHandleLogList(data){
        return request({
            url: getUrl("handleLog/getUcHandleLogList"),
            method: "post",
            data,
        })
    },
    getUcHandleLogPage(data){
        return request({
            url: getUrl("handleLog/getUcHandleLogPage"),
            method: "post",
            data,
        })
    },
}