import request from "@/utils/request";
function getUrl(url) {
    return "/operate/" + url;
}

export default {
    saveUcActivityType(data){
        return request({
            url: getUrl("activityType/saveUcActivityType"),
            method: "post",
            data,
        })
    },
    deleteUcActivityType(data){
        return request({
            url: getUrl("activityType/deleteUcActivityType"),
            method: "post",
            data,
        })
    },
    getUcActivityType(data){
        return request({
            url: getUrl("activityType/getUcActivityTypeVO"),
            method: "post",
            data,
        })
    },
    getUcActivityTypeList(data){
        return request({
            url: getUrl("activityType/getUcActivityTypeList"),
            method: "post",
            data,
        })
    },
    getUcActivityTypePage(data){
        return request({
            url: getUrl("activityType/getUcActivityTypePage"),
            method: "post",
            data,
        })
    },
}