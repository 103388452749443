import request from "@/utils/request";
function getUrl(url) {
    return "/operate/" + url;
}

export default {
    addUcVisitor(data){
        return request({
            url: getUrl("visitor/addUcVisitor"),
            method: "post",
            data,
        })
    },
    deleteUcVisitor(data){
        return request({
            url: getUrl("visitor/deleteUcVisitor"),
            method: "post",
            data,
        })
    },
    getUcVisitor(data){
        return request({
            url: getUrl("visitor/getUcVisitor"),
            method: "post",
            data,
        })
    },
    getUcVisitorPage(data){
        return request({
            url: getUrl("visitor/getUcVisitorPage"),
            method: "post",
            data,
        })
    },
}