import request from "@/utils/request";
function getUrl(url) {
    return "/operate/" + url;
}

export default {
    addUcCouponHistory(data){
        return request({
            url: getUrl("couponHistory/addUcCouponHistory"),
            method: "post",
            data,
        })
    },
    updateUcCouponHistory(data){
        return request({
            url: getUrl("couponHistory/updateUcCouponHistory"),
            method: "post",
            data,
        })
    },
    deleteUcCouponHistory(data){
        return request({
            url: getUrl("couponHistory/deleteUcCouponHistory"),
            method: "post",
            data,
        })
    },
    getUcCouponHistory(data){
        return request({
            url: getUrl("couponHistory/getUcCouponHistory"),
            method: "post",
            data,
        })
    },
    getUcCouponHistoryPage(data){
        return request({
            url: getUrl("couponHistory/getUcCouponHistoryPage"),
            method: "post",
            data,
        })
    },
    getUcCouponOrderPage(data){
        return request({
            url: getUrl("couponHistory/getUcCouponOrderPage"),
            method: "post",
            data,
        })
    },
    getUcAllCouponOrderPage(data){
        return request({
            url: getUrl("couponHistory/getUcAllCouponOrderPage"),
            method: "post",
            data,
        })
    },
}
