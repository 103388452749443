import Vue from "vue";
import VueRouter from "vue-router";
import ecareLay from "@/ecareLay/index.vue";

Vue.use(VueRouter);

// 解决编程式路由往同一地址跳转时会报错的情况
const originalPush = VueRouter.prototype.push;

// push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch((err) => err);
};

export const commonRouters = [
    {
        path: "/login",
        hidden: true,
        meta: {title: "登录", icon: "el-icon-ed-shouye", affix: true},
        component: () => import(/* webpackChunkName: "login" */ "../pages/login/index.vue"),
    },
    {
        path: '/cockpit',
        name: 'home',
        component: () => import(/* webpackChunkName: "LSD.bighome" */ '../pages/cockpit/views/home.vue'),
        children:[
            {
                path: '/cockpit/index',
                meta: {title: "高铁开发集团门户网站数据驾驶舱", icon: "el-icon-ed-shouye", affix: true},
                name: 'index',
                component: () => import(/* webpackChunkName: "LSD.bighome" */ '../pages/cockpit/views/indexs/index.vue'),
            }
        ]
    },
    {
        path: "/order/info",
        hidden: true,
        component: () => import(/* webpackChunkName: "login" */ "../pages/es/order/info.vue"),
    },
    // {
    //     path: "/cockpit",
    //     hidden: true,
    //     meta: {title: "驾驶舱", icon: "el-icon-ed-shouye", affix: true},
    //     component: () => import(/* webpackChunkName: "login" */ "../pages/cockpit/views/indexs/edit.vue"),
    // },
    {
        path: "/",
        component: ecareLay,
        redirect: "/index",
        children: [
            {
                path: "/index",
                meta: {title: "首页", icon: "el-icon-ed-shouye", affix: true},
                component: () => import(/* webpackChunkName: "login" */ "../pages/home/index.vue"),
            }
        ],
    },
    {
        path: "/member",
        component: ecareLay,
        redirect: "/member",
        children: [
            {
                path: "/member/enterprise",
                meta: {title: "企业管理", activeMenu: '/member/enterprise', affix: true},
                component: () => import(/* webpackChunkName: "login" */ "../pages/member/enterprise/list.vue"),
            },
            {
                path: "/member/enterprise/info",
                meta: {title: "企业详情", activeMenu: '/member/enterprise', affix: true},
                component: () => import(/* webpackChunkName: "login" */ "../pages/member/enterprise/info.vue"),
            },
            {
                path: "/member/staff",
                meta: {title: "员工管理", activeMenu: '/member/staff', affix: true},
                component: () => import(/* webpackChunkName: "login" */ "../pages/member/staff/list.vue"),
            },
        ],
    },
    {
        path: "/visitor",
        component: ecareLay,
        redirect: "/visitor",
        children: [
            {
                path: "/visitor/invite",
                meta: {title: "访客邀请", activeMenu: '/visitor/invite', affix: true},
                component: () => import(/* webpackChunkName: "login" */ "../pages/visitor/invite/list.vue"),
            },
            {
                path: "/visitor/visitor",
                meta: {title: "访客信息", activeMenu: '/visitor/visitor', affix: true},
                component: () => import(/* webpackChunkName: "login" */ "../pages/visitor/visitor/visitor.vue"),
            },
            {
                path: "/visitor/registration",
                meta: {title: "访客登记", activeMenu: '/visitor/registration', affix: true},
                component: () => import(/* webpackChunkName: "login" */ "../pages/visitor/registration/list.vue"),
            },
        ],
    },
    {
        path: "/coupon",
        component: ecareLay,
        redirect: "/coupon",
        children: [
            {
                path: "/coupon/coupon",
                meta: {title: "优惠券管理", activeMenu: '/coupon/coupon', affix: true},
                component: () => import(/* webpackChunkName: "login" */ "../pages/coupon/coupon/list.vue"),
            },
            {
                path: "/coupon/coupon/info",
                meta: {title: "优惠券详情", activeMenu: '/coupon/coupon', affix: true},
                component: () => import(/* webpackChunkName: "login" */ "../pages/coupon/coupon/info.vue"),
            },
            {
                path: "/coupon/template",
                meta: {title: "优惠券模版", activeMenu: '/coupon/template', affix: true},
                component: () => import(/* webpackChunkName: "login" */ "../pages/coupon/template/list.vue"),
            },
        ],
    },
    {
        path: "/industry",
        component: ecareLay,
        redirect: "/industry",
        children: [
            {
                path: "/industry/activity",
                meta: {title: "活动管理", activeMenu: '/industry/activity', affix: true},
                component: () => import(/* webpackChunkName: "login" */ "../pages/industry/activity/list.vue"),
            },
            {
                path: "/industry/activityRegistration",
                meta: {title: "活动报名", activeMenu: '/industry/activityRegistration', affix: true},
                component: () => import(/* webpackChunkName: "login" */ "../pages/industry/activityRegistration/list.vue"),
            },
            {
                path: "/industry/activityType",
                meta: {title: "活动分类管理", activeMenu: '/industry/activityType', affix: true},
                component: () => import(/* webpackChunkName: "login" */ "../pages/industry/activityType/list.vue"),
            },
            {
                path: "/industry/information",
                meta: {title: "资讯管理", activeMenu: '/industry/information', affix: true},
                component: () => import(/* webpackChunkName: "login" */ "../pages/industry/information/list.vue"),
            },
            {
                path: "/industry/policy",
                meta: {title: "政策管理", activeMenu: '/industry/policy', affix: true},
                component: () => import(/* webpackChunkName: "login" */ "../pages/industry/policy/list.vue"),
            },
            {
                path: "/industry/policySources",
                meta: {title: "政策来源", activeMenu: '/industry/policySources', affix: true},
                component: () => import(/* webpackChunkName: "login" */ "../pages/industry/policySources/list.vue"),
            },
            {
                path: "/industry/policyType",
                meta: {title: "政策类型", activeMenu: '/industry/policyType', affix: true},
                component: () => import(/* webpackChunkName: "login" */ "../pages/industry/policyType/list.vue"),
            },
            {
                path: "/industry/cs",
                meta: {title: "投诉建议", activeMenu: '/industry/cs', affix: true},
                component: () => import(/* webpackChunkName: "login" */ "../pages/industry/cs/list.vue"),
            },
        ],
    },
    {
        path: "/portalSite",
        component: ecareLay,
        redirect: "/portalSite",
        children: [
            {
                path: "/portalSite/site",
                meta: {title: "园区掠影", activeMenu: '/portalSite/site', affix: true},
                component: () => import(/* webpackChunkName: "login" */ "../pages/portalSite/site/list.vue"),
            },
            {
                path: "/portalSite/park",
                meta: {title: "园区掠影", activeMenu: '/portalSite/park', affix: true},
                component: () => import(/* webpackChunkName: "login" */ "../pages/portalSite/park/list.vue"),
            },
            {
                path: "/portalSite/links",
                meta: {title: "友情链接", activeMenu: '/portalSite/links', affix: true},
                component: () => import(/* webpackChunkName: "login" */ "../pages/portalSite/links/list.vue"),
            },
            {
                path: "/portalSite/column",
                meta: {title: "新闻栏目", activeMenu: '/portalSite/column', affix: true},
                component: () => import(/* webpackChunkName: "login" */ "../pages/portalSite/column/list.vue"),
            },
            {
                path: "/portalSite/news",
                meta: {title: "新闻管理", activeMenu: '/portalSite/news', affix: true},
                component: () => import(/* webpackChunkName: "login" */ "../pages/portalSite/news/list.vue"),
            },
            {
                path: "/portalSite/menu",
                meta: {title: "菜单管理", activeMenu: '/portalSite/menu', affix: true},
                component: () => import(/* webpackChunkName: "login" */ "../pages/portalSite/menu/list.vue"),
            },
            {
                path: "/portalSite/serve",
                meta: {title: "服务管理", activeMenu: '/portalSite/serve', affix: true},
                component: () => import(/* webpackChunkName: "login" */ "../pages/portalSite/serve/list.vue"),
            },
            {
                path: "/portalSite/cbd",
                meta: {title: "商业区管理", activeMenu: '/portalSite/cbd', affix: true},
                component: () => import(/* webpackChunkName: "login" */ "../pages/portalSite/cbd/list.vue"),
            },
            {
                path: "/portalSite/industrialPark",
                meta: {title: "产业园入驻", activeMenu: '/portalSite/industrialPark', affix: true},
                component: () => import(/* webpackChunkName: "login" */ "../pages/portalSite/industrialPark/list.vue"),
            },
            {
                path: "/portalSite/articleTemplate",
                meta: {title: "文章模板管理", activeMenu: '/portalSite/articleTemplate', affix: true},
                component: () => import(/* webpackChunkName: "login" */ "../pages/portalSite/articleTemplate/template/list.vue"),
            },
            // {
            //     path: "/portalSite/commercial/info",
            //     meta: {title: "商业区详情管理", icon: "el-icon-ed-shouye", affix: true},
            //     component: () => import(/* webpackChunkName: "login" */ "../pages/portalSite/commercial/info/list.vue"),
            // },
            {
                path: "/portalSite/customInfo",
                meta: {title: "信息填报管理", activeMenu: '/portalSite/customInfo', affix: true},
                component: () => import(/* webpackChunkName: "login" */ "../pages/portalSite/customInfo/list.vue"),
            },
        ],
    },
    {
        path: "/about",
        component: ecareLay,
        redirect: "/about",
        children: [
            {
                path: "/about",
                meta: {title: "关于我们", activeMenu: '/about', affix: true},
                component: () => import(/* webpackChunkName: "login" */ "../pages/about/index.vue"),
            },
            {
                path: "/about/website",
                meta: {title: "官网信息", activeMenu: '/about/website', affix: true},
                component: () => import(/* webpackChunkName: "login" */ "../pages/about/website/index.vue"),
            },
            {
                path: "/about/rotationChart",
                meta: {title: "轮播图管理", activeMenu: '/about/rotationChart', affix: true},
                component: () => import(/* webpackChunkName: "login" */ "../pages/about/rotationChart/index.vue"),
            }
        ],
    },
    {
        path: "/overview",
        component: ecareLay,
        redirect: "/overview",
        children: [
            {
                path: "/overview/trend",
                meta: {title: "数据统计", activeMenu: '/overview/trend', affix: true},
                component: () => import(/* webpackChunkName: "login" */ "../pages/overview/trend/index.vue"),
            },
            {
                path: "/overview/realTime",
                meta: {title: "实时访客", activeMenu: '/overview/realTime', affix: true},
                component: () => import(/* webpackChunkName: "login" */ "../pages/overview/realTime/index.vue"),
            },
            {
                path: "/overview/mp",
                meta: {title: "公众号数据分析", activeMenu: '/overview/mp', affix: true},
                component: () => import(/* webpackChunkName: "login" */ "../pages/overview/mp/index.vue"),
            },
        ],
    },
    {
        path: "/system",
        component: ecareLay,
        redirect: "/system",
        children: [
            {
                path: "/system/user",
                meta: {title: "用户管理", activeMenu: '/system/user', affix: true},
                component: () => import(/* webpackChunkName: "login" */ "../pages/system/user/list.vue"),
            },
            {
                path: "/system/user/info",
                meta: {title: "用户信息", activeMenu: '/system/user', affix: true},
                component: () => import(/* webpackChunkName: "login" */ "../pages/system/user/info.vue"),
            },
            {
                path: "/system/menu",
                meta: {title: "菜单管理", activeMenu: '/system/menu', affix: true},
                component: () => import(/* webpackChunkName: "login" */ "../pages/system/menu/list.vue"),
            },
            {
                path: "/system/role",
                meta: {title: "角色管理", activeMenu: '/system/role', affix: true},
                component: () => import(/* webpackChunkName: "login" */ "../pages/system/role/list.vue"),
            },
        ],
    },
    {
        path: "/es",
        component: ecareLay,
        redirect: "/es",
        children: [
            {
                path: "/es/products",
                meta: {title: "商品管理", activeMenu: '/es/products', affix: true},
                component: () => import(/* webpackChunkName: "login" */ "../pages/es/products/list.vue"),
            },
            {
                path: "/es/order",
                meta: {title: "订单管理", activeMenu: '/es/order', affix: true},
                component: () => import(/* webpackChunkName: "login" */ "../pages/es/order/list.vue"),
            },
            {
                path: "/es/order/info",
                meta: {title: "订单详情", activeMenu: '/es/order', affix: true},
                component: () => import(/* webpackChunkName: "login" */ "../pages/es/order/info.vue"),
            },
            {
                path: "/es/staffRepair",
                meta: {title: "报修管理", activeMenu: '/es/staffRepair', affix: true},
                component: () => import(/* webpackChunkName: "login" */ "../pages/es/staffRepair/list.vue"),
            },
            {
                path: "/es/clockIn",
                meta: {title: "报修打卡管理", activeMenu: '/es/clockIn', affix: true},
                component: () => import(/* webpackChunkName: "login" */ "../pages/es/clockIn/list.vue"),
            },
        ],
    },
    {
        path: "/notice",
        component: ecareLay,
        redirect: "/notice",
        children: [
            {
                path: "/notice",
                meta: {title: "通知管理", activeMenu: '/notice', affix: true},
                component: () => import(/* webpackChunkName: "login" */ "../pages/notice/list.vue"),
            },
        ],
    },
    {
        path: "/ticket",
        component: ecareLay,
        redirect: "/ticket",
        children: [
            {
                path: "/ticket/list",
                meta: {title: "工单管理", activeMenu: '/ticket/list', affix: true},
                component: () => import(/* webpackChunkName: "login" */ "../pages/ticket/list.vue"),
            },
            {
                path: "/ticket/template",
                meta: {title: "工单模版", activeMenu: '/ticket/template', affix: true},
                component: () => import(/* webpackChunkName: "login" */ "../pages/ticket/template/list.vue"),
            },
        ],
    },
    {
        path: "/wechat",
        component: ecareLay,
        redirect: "/wechat",
        children: [
            {
                path: "/wechat/list",
                meta: {title: "微信三方管理", activeMenu: '/wechat/list', affix: true},
                component: () => import(/* webpackChunkName: "login" */ "../pages/wechat/list.vue"),
            },
        ],
    },
    {
        path: "/ic",
        component: ecareLay,
        redirect: "/ic/intendedCustomer",
        meta: {title: "意向客户", icon: "el-icon-ed-shouye", affix: false},
        children: [
            {
                path: "intendedCustomer",
                meta: {title: "意向客户管理", activeMenu: '/intendedCustomer', affix: false},
                component: () => import(/* webpackChunkName: "login" */ "../pages/intendedCustomer/list.vue"),
            },
            {
                path: "intendedCustomer/consult",
                meta: {title: "咨询登记", activeMenu: '/intendedCustomer', affix: false, parent: "/ic/intendedCustomer"},
                component: () => import(/* webpackChunkName: "login" */ "../pages/intendedCustomer/consult/list.vue"),
            },
            {
                path: "intendedCustomer/followup",
                meta: {title: "回访记录", activeMenu:'/intendedCustomer', affix: false, parent: "/intendedCustomer/consult"},
                component: () => import(/* webpackChunkName: "login" */ "../pages/intendedCustomer/followup/list.vue"),
            },
        ],
    },
    {
        path: "/monitor",
        component: ecareLay,
        redirect: "/monitor",
        children: [
            {
                path: "/monitor/system",
                meta: {title: "系统监控", activeMenu:'/monitor/system', affix: true},
                component: () => import(/* webpackChunkName: "login" */ "../pages/monitor/system/index.vue"),
            },
            {
                path: "/monitor/druid",
                meta: {title: "服务监控", activeMenu:'/monitor/druid', affix: true},
                component: () => import(/* webpackChunkName: "login" */ "../pages/monitor/druid/index.vue"),
            },
            {
                path: "/monitor/log",
                meta: {title: "系统日志", activeMenu:'/monitor/log', affix: true},
                component: () => import(/* webpackChunkName: "login" */ "../pages/monitor/log/list.vue"),
            },
            {
                path: "/monitor/log/info",
                meta: {title: "系统日志详情", activeMenu:'/monitor/log', affix: true},
                component: () => import(/* webpackChunkName: "login" */ "../pages/monitor/log/info.vue"),
            },
        ],
    },
    {
        path: "/bpmn",
        component: ecareLay,
        redirect: "/bpmn/list",
        children: [
            {
                path: "/bpmn/list",
                meta: {title: "流程管理", activeMenu:'/bpmn/list', affix: true},
                component: () => import(/* webpackChunkName: "login" */ "../pages/bpmn/list.vue"),
            },
            {
                path: "/bpmn/edit",
                meta: {title: "流程编辑", activeMenu:'/bpmn/list', affix: true},
                component: () => import(/* webpackChunkName: "login" */ "../pages/bpmn/edit.vue"),
            },
        ],
    },
    {
        path: "/401",
        hidden: true,
        meta: {title: "401", icon: "el-icon-ed-shouye", affix: true},
        component: () => import(/* webpackChunkName: "login" */ "../pages/common/401/index.vue"),
    },
]

const createRouter = () =>
    new VueRouter({
        mode: "history",
        base: process.env.BASE_URL,
        routes: commonRouters,
    });

const router = createRouter();

export function resetRouter() {
    const newRouter = createRouter();
    router.matcher = newRouter.matcher; // reset router
}

export default router;
