import request from "@/utils/request";
function getUrl(url) {
    return "/operate/" + url;
}

export default {
    saveUcComplaintsSuggestions(data){
        return request({
            url: getUrl("complaintsSuggestions/saveUcComplaintsSuggestions"),
            method: "post",
            data,
        })
    },
    deleteUcComplaintsSuggestions(data){
        return request({
            url: getUrl("complaintsSuggestions/deleteUcComplaintsSuggestions"),
            method: "post",
            data,
        })
    },
    getUcComplaintsSuggestionsVo(data){
        return request({
            url: getUrl("complaintsSuggestions/getUcComplaintsSuggestionsVo"),
            method: "post",
            data,
        })
    },
    getUcComplaintsSuggestionsPage(data){
        return request({
            url: getUrl("complaintsSuggestions/getUcComplaintsSuggestionsPage"),
            method: "post",
            data,
        })
    },
}