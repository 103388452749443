<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>

export default {
  name: 'App'
}
</script>

<style>
body{
  padding: 0;
  margin: 0;
}


.tox-tinymce-aux {
  z-index: 99999 !important;
}

.tinymce.ui.FloatPanel {
  z-Index: 99;
}

.el-loading-mask {
  z-index: 99999!important;
}
</style>
