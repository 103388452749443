import dayjs from 'dayjs'

export function dateFormat(val){
    if (val!=null){
       let  date =dayjs(val).format('YYYY-MM-DD HH:mm:ss')
        if (date==='2099-01-01 00:00:00'){
            return "长期"
        }
        return date
    }else {
        return "暂无数据"
    }
}

export function recipeDateFormat(val) {
    if (val != null) {
        return dayjs(val).format('YYYY-MM-DD')
    } else {
        return "-"
    }
}

export function clickInDateFormat(val){
    if (val!=null){
        let  date =dayjs(val).format('YYYY-MM-DD HH:mm')
        if (date==='2099-01-01 00:00:00'){
            return "长期"
        }
        return date
    }else {
        return "暂无数据"
    }
}

export function birthDateFormat(val){
    if (val!=null){
        return dayjs(val).format('YYYY-MM-DD')
    }else {
        return "未填写"
    }
}

export function sexType(value) {
    let sex = "";
    switch (value){
        case 0:
            sex = '男';
            break;
        case 1:
            sex = '女';
            break;
        default:
            sex = '未知';
            break;
    }
    return sex;
}

export function couponType(value) {
    let coupon = "";
    switch (value){
        case 0:
            coupon = '满减';
            break;
        case 1:
            coupon = '立减';
            break;
        default:
            coupon = '折扣';
            break;
    }
    return coupon;
}

export function activityStatus(value) {
    let state = "";
    switch (value){
        case 0:
            state = '启用';
            break;
        case 1:
            state = '未启用';
            break;
        default:
            state = '已过期';
            break;
    }
    return state;
}

export function sjType(value) {
    let state = "";
    switch (value){
        case 0:
            state = '时';
            break;
        case 1:
            state = '分';
            break;
        case 2:
            state = '天';
            break;
        case 3:
            state = '月';
            break;
        default:
            state = '年';
            break;
    }
    return state;
}

export function orderStatus(value) {
    let state = "";
    switch (value){
        case 0:
            state = '未支付';
            break;
        case 1:
            state = '已支付';
            break;
        case 2:
            state = '已退款';
            break;
        case 6:
            state = '已取消';
            break;
        default:
            state = '未知';
            break;
    }
    return state;
}

export function channel(value) {
    let state = "";
    switch (value){
        case 1:
            state = '在线咨询';
            break;
        case 2:
            state = '线下咨询';
            break;
        case 3:
            state = '客户自填';
            break;
        default:
            state = '其它';
            break;
    }
    return state;
}

export function consultChannel(value) {
    let state = "";
    switch (value){
        case 1:
            state = '微信';
            break;
        case 2:
            state = '电话';
            break;
        case 3:
            state = '来访登记';
            break;
        default:
            state = '其它';
            break;
    }
    return state;
}

export function followupWay(value) {
    let state = "";
    switch (value){
        case 1:
            state = '微信';
            break;
        case 2:
            state = '电话';
            break;
        case 3:
            state = '邮件';
            break;
        case 4:
            state = '线下';
            break;
        default:
            state = '其它';
            break;
    }
    return state;
}

export function intendedCustomerStatus(value) {
    let state = "";
    switch (value){
        case 1:
            state = '意向客户';
            break;
        case 2:
            state = '正式客户';
            break;
        default:
            state = '正式客户';
            break;
    }
    return state;
}

export function consultStatus(value) {
    let state = "";
    switch (value){
        case 1:
            state = '待回访';
            break;
        default:
            state = '已回访';
            break;
    }
    return state;
}

export function repairStatus(value) {
    let state = "";
    switch (value){
        case 0:
            state = '已申请';
            break;
        case 1:
            state = '处理中';
            break;
        case 2:
            state = '已拒绝';
            break;
        case 3:
            state = '已完成';
            break;
        default:
            state = '未知状态';
            break;
    }
    return state;
}

export function isAllocation(value) {
    let state = "";
    switch (value){
        case 0:
            state = '待分配';
            break;
        case 1:
            state = '已分配';
            break;
        default:
            state = '未知状态';
            break;
    }
    return state;
}

export function couponUseType(value) {
    let coupon = "";
    switch (value) {
        case 0:
            coupon = '全场通用';
            break;
        case 1:
            coupon = '企业服务商品';
            break;
        case 3:
            coupon = '共享空间商品';
            break;
        case 4:
            coupon = '空间商品';
            break;
        default:
            coupon = '未知';
            break;
    }
    return coupon;
}

export function leavingReason(value) {
    let coupon = "";
    switch (value) {
        case 0:
            coupon = '合同正常终止';
            break;
        case 1:
            coupon = '合同异常终止';
            break;
        case 3:
            coupon = '其它';
            break;
        default:
            coupon = '-';
            break;
    }
    return coupon;
}

export function leavingDestination(value) {
    let coupon = "";
    switch (value) {
        case 0:
            coupon = '其他园区';
            break;
        case 1:
            coupon = '企业停业';
            break;
        case 3:
            coupon = '其它';
            break;
        default:
            coupon = '-';
            break;
    }
    return coupon;
}

export default {
    install(Vue) {
        Vue.filter('dateFormat', dateFormat)
        Vue.filter('recipeDateFormat', recipeDateFormat)
        Vue.filter('birthDateFormat', birthDateFormat)
        Vue.filter('clickInDateFormat', clickInDateFormat)
        Vue.filter('sexType', sexType)
        Vue.filter('couponType', couponType)
        Vue.filter('activityStatus', activityStatus)
        Vue.filter('sjType', sjType)
        Vue.filter('orderStatus', orderStatus)
        Vue.filter('channel', channel)
        Vue.filter('intendedCustomerStatus', intendedCustomerStatus)
        Vue.filter('consultChannel', consultChannel)
        Vue.filter('consultStatus', consultStatus)
        Vue.filter('followupWay', followupWay)
        Vue.filter('repairStatus', repairStatus)
        Vue.filter('isAllocation', isAllocation)
        Vue.filter('couponUseType', couponUseType)
        Vue.filter('leavingReason', leavingReason)
        Vue.filter('leavingDestination', leavingDestination)
    }
}