import request from "@/utils/request";
function getUrl(url) {
    return "/operate/" + url;
}

export default {
    saveUcActivityInfo(data){
        return request({
            url: getUrl("activity/saveUcActivityInfo"),
            method: "post",
            data,
        })
    },
    deleteUcActivityInfo(data){
        return request({
            url: getUrl("activity/deleteUcActivityInfo"),
            method: "post",
            data,
        })
    },
    getUcActivityInfoVO(data){
        return request({
            url: getUrl("activity/getUcActivityInfoVO"),
            method: "post",
            data,
        })
    },
    getUcActivityInfoPage(data){
        return request({
            url: getUrl("activity/getUcActivityInfoPage"),
            method: "post",
            data,
        })
    },
    getUcActivityInfoList(data){
        return request({
            url: getUrl("activity/getUcActivityInfoList"),
            method: "post",
            data,
        })
    },
}