import request from "@/utils/request";
function getUrl(url) {
    return "/operate/" + url;
}

export default {
    save(data){
        return request({
            url: getUrl("visitorInvite/save"),
            method: "post",
            data,
        })
    },
    del(data){
        return request({
            url: getUrl("visitorInvite/del"),
            method: "post",
            data,
        })
    },
    info(data){
        return request({
            url: getUrl("visitorInvite/info"),
            method: "post",
            data,
        })
    },
    page(data){
        return request({
            url: getUrl("visitorInvite/page"),
            method: "post",
            data,
        })
    },
}