import request from "@/utils/request";
function getUrl(url) {
    return "/operate/" + url;
}
export default {
    getUcBrokerList(data){
        return request({
            url: getUrl("business/getUcBrokerList"),
            method: "post",
            data,
        })
    },
    saveUcCustom(data){
        return request({
            url: getUrl("business/saveUcCustom"),
            method: "post",
            data,
        })
    },
    getUcSpaceGoodsOrdersPage(data){
        return request({
            url: getUrl("business/getUcSpaceGoodsOrdersPage"),
            method: "post",
            data,
        })
    },
    getUcShareSpaceOrderPage(data){
        return request({
            url: getUrl("business/getUcShareSpaceOrderPage"),
            method: "post",
            data,
        })
    },
}
