import request from "@/utils/request";
function getUrl(url) {
    return "/operate/" + url;
}

export default {
    saveUcStaffRepair(data){
        return request({
            url: getUrl("staffRepair/saveUcStaffRepair"),
            method: "post",
            data,
        })
    },
    deleteUcStaffRepair(data){
        return request({
            url: getUrl("staffRepair/deleteUcStaffRepair"),
            method: "post",
            data,
        })
    },
    getUcStaffRepair(data){
        return request({
            url: getUrl("staffRepair/getUcStaffRepair"),
            method: "post",
            data,
        })
    },
    getUcStaffRepairList(data){
        return request({
            url: getUrl("staffRepair/getUcStaffRepairList"),
            method: "post",
            data,
        })
    },
    getUcStaffRepairPage(data){
        return request({
            url: getUrl("staffRepair/getUcStaffRepairPage"),
            method: "post",
            data,
        })
    },
    allocation(data){
        return request({
            url: getUrl("staffRepair/allocation"),
            method: "post",
            data,
        })
    },
}
