import request from "@/utils/request";
function getUrl(url) {
    return "/operate/" + url;
}

export default {
    saveUcCustomInfo(data){
        return request({
            url: getUrl("customInfo/saveUcCustomInfo"),
            method: "post",
            data,
        })
    },
    deleteUcCustomInfo(data){
        return request({
            url: getUrl("customInfo/deleteUcCustomInfo"),
            method: "post",
            data,
        })
    },
    getUcCustomInfoVo(data){
        return request({
            url: getUrl("customInfo/getUcCustomInfoVo"),
            method: "post",
            data,
        })
    },
    getUcCustomInfoPage(data){
        return request({
            url: getUrl("customInfo/getUcCustomInfoPage"),
            method: "post",
            data,
        })
    },
}