import request from "@/utils/request";
function getUrl(url) {
    return "/operate/" + url;
}

export default {
    saveUcArticleTemplate(data){
        return request({
            url: getUrl("articleTemplate/saveUcArticleTemplate"),
            method: "post",
            data,
        })
    },
    deleteUcArticleTemplate(data){
        return request({
            url: getUrl("articleTemplate/deleteUcArticleTemplate"),
            method: "post",
            data,
        })
    },
    getUcArticleTemplateVO(data){
        return request({
            url: getUrl("articleTemplate/getUcArticleTemplateVO"),
            method: "post",
            data,
        })
    },
    getUcArticleTemplateList(data){
        return request({
            url: getUrl("articleTemplate/getUcArticleTemplateList"),
            method: "post",
            data,
        })
    },
    getUcArticleTemplatePage(data){
        return request({
            url: getUrl("articleTemplate/getUcArticleTemplatePage"),
            method: "post",
            data,
        })
    },
    batchImport(data){
        return request({
            url: getUrl("articleTemplate/batchImport"),
            method: "post",
            data,
        })
    },
}
