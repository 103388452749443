import request from "@/utils/request";
function getUrl(url) {
    return "/operate/" + url;
}

export default {
    /**
     * 获取用户增减数据的接口
     */
    getUserSummary(data){
        return request({
            url: getUrl("wx/getUserSummary"),
            method: "post",
            data,
        })
    },
    /**
     * 获取累计用户数据的接口
     */
    getUserCumulate(data){
        return request({
            url: getUrl("wx/getUserCumulate"),
            method: "post",
            data,
        })
    },
    /**
     * 获取消息发送概况数据
     */
    getUpstreamMessage(data){
        return request({
            url: getUrl("wx/getUpstreamMessage"),
            method: "post",
            data,
        })
    },
    /**
     * 获取接口分析数据
     */
    getInterfaceSummary(data){
        return request({
            url: getUrl("wx/getInterfaceSummary"),
            method: "post",
            data,
        })
    }
}
