import request from "@/utils/request";
function getUrl(url) {
    return "/operate/" + url;
}

export default {
    addUcCouponTemplate(data){
        return request({
            url: getUrl("couponTemplate/addUcCouponTemplate"),
            method: "post",
            data,
        })
    },
    deleteUcCouponTemplate(data){
        return request({
            url: getUrl("couponTemplate/deleteUcCouponTemplate"),
            method: "post",
            data,
        })
    },
    getUcCouponTemplate(data){
        return request({
            url: getUrl("couponTemplate/getUcCouponTemplate"),
            method: "post",
            data,
        })
    },
    getUcCouponTemplatePage(data){
        return request({
            url: getUrl("couponTemplate/getUcCouponTemplatePage"),
            method: "post",
            data,
        })
    },
    getUcCouponTemplateList(data){
        return request({
            url: getUrl("couponTemplate/getUcCouponTemplateList"),
            method: "post",
            data,
        })
    },
}