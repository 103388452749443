import request from "@/utils/request";
function getUrl(url) {
    return "/operate/" + url;
}

export default {
    saveWechatAuthorizationInfo(data){
        return request({
            url: getUrl("wechat/saveWechatAuthorizationInfo"),
            method: "post",
            data,
        })
    },
    deleteWechatAuthorizationInfo(data){
        return request({
            url: getUrl("wechat/deleteWechatAuthorizationInfo"),
            method: "post",
            data,
        })
    },
    getWechatAuthorizationInfoVo(data){
        return request({
            url: getUrl("wechat/getWechatAuthorizationInfoVo"),
            method: "post",
            data,
        })
    },
    getWechatAuthorizationInfoPage(data){
        return request({
            url: getUrl("wechat/getWechatAuthorizationInfoPage"),
            method: "post",
            data,
        })
    },
}