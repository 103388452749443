import request from "@/utils/request";
function getUrl(url) {
    return "/operate/" + url;
}

export default {
    getTimeTrendRpt(data){
        return request({
            url: getUrl("report/getTimeTrendRpt"),
            method: "post",
            data,
        })
    },
    getRealTime(data){
        return request({
            url: getUrl("report/getRealTime"),
            method: "post",
            data,
        })
    },
    getOnLine(data){
        return request({
            url: getUrl("report/getOnLine"),
            method: "post",
            data,
        })
    },
    getVisitorData(data){
        return request({
            url: getUrl("report/getVisitorData"),
            method: "post",
            data,
        })
    },
    getVisitTopPage(data){
        return request({
            url: getUrl("report/getVisitTopPage"),
            method: "post",
            data,
        })
    },
    getFlowAnalysis(data){
        return request({
            url: getUrl("report/getFlowAnalysis/"+data),
            method: "get"
        })
    },
    downloadStatistics(data){
        return request({
            url: getUrl("report/downloadStatistics/"+data),
            method: "get"
        })
    },
}
