import axios from 'axios'
import router from '../router'
// import store from '@/store'
import {getToken, removeToken, setToken} from '@/utils/auth'

// create an axios instance
const service = axios.create({
    // baseURL: process.env.VUE_APP_BASE_API,
    baseURL: "https://gateway.wlmqgtzs.com", // url = base url + request url
    // withCredentials: true, // send cookies when cross-domain requests
    timeout: 600000 // request timeout
})

service.interceptors.request.use(
    config => {
        // do something before request is sent
        if (getToken()) {
            config.headers['UchungToken'] = getToken()
        }
        return config
    },
    error => {
        console.log(error) // for debug
        return Promise.reject(error)
    }
)

// response interceptor
service.interceptors.response.use(
    response => {
        console.log("response", response)
        if (response.headers['UchungToken'] != undefined) {
            setToken(response.headers['UchungToken'])
        }
        const res = response.data


        if (res.code !== 0) {
            if (res.code == 500) {
                removeToken();
                router.push(`/login?redirect=` + router.currentRoute.fullPath)
            } else if (res.code == 500001) {
                console.log("删除失败")
                console.log(res.message)
                return Promise.reject({code: res.code, msg: res.message})
            } else {
                return Promise.reject(res)
            }
        } else {
            return res
        }
    },
    error => {
        console.log(error.response)
        if (error.response.status == 401) {
            return Promise.reject({code: 401, data: null, msg: "暂无权限"})
        } else {
            // Message({
            //     message: error.message,
            //     type: 'error',
            //     duration: 5 * 1000
            // })
            return Promise.reject(error)
        }
    }
)

export default service