//接口相关的方法
const files = require.context(".", true, /\.js$/);

const modules = {};
files.keys().forEach((key) => {
    if (key === "./index.js") return;
    const module = key.slice(2, -3);
    modules[module] = files(key).default;
});






export default modules;
