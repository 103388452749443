import request from "@/utils/request";
function getUrl(url) {
    return "/operate/" + url;
}

export default {
    addUcCoupon(data){
        return request({
            url: getUrl("coupon/addUcCoupon"),
            method: "post",
            data,
        })
    },
    publish(data){
        return request({
            url: getUrl("coupon/publish"),
            method: "post",
            data,
        })
    },
    deleteUcCoupon(data){
        return request({
            url: getUrl("coupon/deleteUcCoupon"),
            method: "post",
            data,
        })
    },
    getUcCoupon(data){
        return request({
            url: getUrl("coupon/getUcCoupon"),
            method: "post",
            data,
        })
    },
    getUcCouponPage(data){
        return request({
            url: getUrl("coupon/getUcCouponPage"),
            method: "post",
            data,
        })
    },
    grant(data){
        return request({
            url: getUrl("coupon/grant"),
            method: "post",
            data,
        })
    },
    revoke(data){
        return request({
            url: getUrl("coupon/revoke"),
            method: "post",
            data,
        })
    },
}
