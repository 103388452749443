import request from "@/utils/request";
function getUrl(url) {
    return "/operate/" + url;
}

export default {
    saveUcNotice(data){
        return request({
            url: getUrl("notice/saveUcNotice"),
            method: "post",
            data,
        })
    },
    deleteUcNotice(data){
        return request({
            url: getUrl("notice/deleteUcNotice"),
            method: "post",
            data,
        })
    },
    getUcNoticeVo(data){
        return request({
            url: getUrl("notice/getUcNoticeVo"),
            method: "post",
            data,
        })
    },
    getUcNoticePage(data){
        return request({
            url: getUrl("notice/getUcNoticePage"),
            method: "post",
            data,
        })
    },
}