import request from "@/utils/request";
function getUrl(url) {
    return "/operate/" + url;
}

export default {
    saveUcPolicy(data){
        return request({
            url: getUrl("policy/saveUcPolicy"),
            method: "post",
            data,
        })
    },
    deleteUcPolicy(data){
        return request({
            url: getUrl("policy/deleteUcPolicy"),
            method: "post",
            data,
        })
    },
    getUcPolicyVO(data){
        return request({
            url: getUrl("policy/getUcPolicyVO"),
            method: "post",
            data,
        })
    },
    getUcPolicyPage(data){
        return request({
            url: getUrl("policy/getUcPolicyPage"),
            method: "post",
            data,
        })
    },
}