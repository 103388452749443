import request from "@/utils/request";
function getUrl(url) {
    return "/operate/" + url;
}

export default {
    saveUcInformation(data){
        return request({
            url: getUrl("information/saveUcInformation"),
            method: "post",
            data,
        })
    },
    deleteUcInformation(data){
        return request({
            url: getUrl("information/deleteUcInformation"),
            method: "post",
            data,
        })
    },
    getUcInformationVO(data){
        return request({
            url: getUrl("information/getUcInformationVO"),
            method: "post",
            data,
        })
    },
    getUcInformationPage(data){
        return request({
            url: getUrl("information/getUcInformationPage"),
            method: "post",
            data,
        })
    },
}