import request from "@/utils/request";
function getUrl(url) {
    return "/operate/" + url;
}

export default {
    saveUcLinks(data){
        return request({
            url: getUrl("links/saveUcLinks"),
            method: "post",
            data,
        })
    },
    deleteUcLinks(data){
        return request({
            url: getUrl("links/deleteUcLinks"),
            method: "post",
            data,
        })
    },
    getUcLinksVo(data){
        return request({
            url: getUrl("links/getUcLinksVo"),
            method: "post",
            data,
        })
    },
    getUcLinksPage(data){
        return request({
            url: getUrl("links/getUcLinksPage"),
            method: "post",
            data,
        })
    },
}