import request from "@/utils/request";
function getUrl(url) {
    return "/operate/" + url;
}

export default {
    getSysTaskInfo(){
        return request({
            url: getUrl("auth/getSysTaskInfo"),
            method: "get",
        })
    },
}