import request from "@/utils/request";
function getUrl(url) {
    return "/operate/" + url;
}

export default {
    saveUcNavigation(data){
        return request({
            url: getUrl("navigation/saveUcNavigation"),
            method: "post",
            data,
        })
    },
    deleteUcNavigation(data){
        return request({
            url: getUrl("navigation/deleteUcNavigation"),
            method: "post",
            data,
        })
    },
    getUcNavigationVO(data){
        return request({
            url: getUrl("navigation/getUcNavigationVO"),
            method: "post",
            data,
        })
    },
    getUcNavigationList(data){
        return request({
            url: getUrl("navigation/getUcNavigationList"),
            method: "post",
            data,
        })
    },
    getUcNavigationPage(data){
        return request({
            url: getUrl("navigation/getUcNavigationPage"),
            method: "post",
            data,
        })
    },
}