import request from "@/utils/request";
function getUrl(url) {
    return "/operate/" + url;
}

export default {
    saveUcCommercialDistrict(data){
        return request({
            url: getUrl("ucCommercialDistrict/saveUcCommercialDistrict"),
            method: "post",
            data,
        })
    },
    deleteUcCommercialDistrict(data){
        return request({
            url: getUrl("ucCommercialDistrict/deleteUcCommercialDistrict"),
            method: "post",
            data,
        })
    },
    getUcCommercialDistrictVO(data){
        return request({
            url: getUrl("ucCommercialDistrict/getUcCommercialDistrictVO"),
            method: "post",
            data,
        })
    },
    getUcCommercialDistrictPage(data){
        return request({
            url: getUrl("ucCommercialDistrict/getUcCommercialDistrictPage"),
            method: "post",
            data,
        })
    },
}