import request from "@/utils/request";
function getUrl(url) {
    return "/operate/" + url;
}
export default {
    getName(data){
        return request({
            url: getUrl("cockpit/getName.json"),
            method: "post",
            data,
        })
    },
}
