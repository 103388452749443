import request from "@/utils/request";
function getUrl(url) {
    return "/operate/" + url;
}

export default {
    save(data) {
        return request({
            url: getUrl("activiti/"+data.modelId+"/save"),
            headers: {
                'Content-Type':'multipart/form-data'
            },
            method: "put",
            data,
        })
    },
    add(data) {
        return request({
            url: getUrl("activiti/add"),
            headers: {
                'Content-Type':'multipart/form-data'
            },
            method: "put",
            data,
        })
    },
    modelList(data) {
        return request({
            url: getUrl("activiti/modelList"),
            method: "get",
            data,
        })
    },
    getJson(data) {
        return request({
            url: getUrl("activiti/"+data.modelId+"/json"),
            method: "get",
            data,
        })
    },
    delModel(data) {
        return request({
            url: getUrl("activiti/delModel"),
            headers: {
                'Content-Type':'multipart/form-data'
            },
            method: "post",
            data,
        })
    },
    getSvg(id) {
        return request({
            url: getUrl("activiti/model/svg/"+id),
            method: "get"
        })
    },
}
