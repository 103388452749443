import request from "@/utils/request";
function getUrl(url) {
    return "/operate/" + url;
}

export default {
    saveUcSite(data){
        return request({
            url: getUrl("site/saveUcSite"),
            method: "post",
            data,
        })
    },
    deleteUcSite(data){
        return request({
            url: getUrl("site/deleteUcSite"),
            method: "post",
            data,
        })
    },
    getUcSite(data){
        return request({
            url: getUrl("site/getUcSite"),
            method: "post",
            data,
        })
    },
    batchImport(data){
        return request({
            url: getUrl("site/batchImport"),
            method: "post",
            data,
        })
    },
    getUcSiteList(data){
        return request({
            url: getUrl("site/getUcSiteList"),
            method: "post",
            data,
        })
    },
    getUcSitePage(data){
        return request({
            url: getUrl("site/getUcSitePage"),
            method: "post",
            data,
        })
    },
}
