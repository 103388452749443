import request from "@/utils/request";
function getUrl(url) {
    return "/operate/" + url;
}

export default {
    saveUcCommercialDistrictsInfo(data){
        return request({
            url: getUrl("commercialDistrictsInfo/saveUcCommercialDistrictsInfo"),
            method: "post",
            data,
        })
    },
    deleteUcCommercialDistrictsInfo(data){
        return request({
            url: getUrl("commercialDistrictsInfo/deleteUcCommercialDistrictsInfo"),
            method: "post",
            data,
        })
    },
    getUcCommercialDistrictsInfoVO(data){
        return request({
            url: getUrl("commercialDistrictsInfo/getUcCommercialDistrictsInfoVO"),
            method: "post",
            data,
        })
    },
    getUcCommercialDistrictsInfoPage(data){
        return request({
            url: getUrl("commercialDistrictsInfo/getUcCommercialDistrictsInfoPage"),
            method: "post",
            data,
        })
    },
}