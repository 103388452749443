import request from "@/utils/request";
function getUrl(url) {
    return "/operate/" + url;
}

export default {
    getDataInfoForAdmin(data) {
        return request({
            url: getUrl("display/getDataInfoForAdmin"),
            method: "post",
            data,
        })
    },
}
