import request from "@/utils/request";
function getUrl(url) {
    return "/operate/" + url;
}

export default {
    saveUcNewsColumn(data){
        return request({
            url: getUrl("column/saveUcNewsColumn"),
            method: "post",
            data,
        })
    },
    deleteUcNewsColumn(data){
        return request({
            url: getUrl("column/deleteUcNewsColumn"),
            method: "post",
            data,
        })
    },
    getUcNewsColumnVo(data){
        return request({
            url: getUrl("column/getUcNewsColumnVo"),
            method: "post",
            data,
        })
    },
    getUcNewsColumnList(data){
        return request({
            url: getUrl("column/getUcNewsColumnList"),
            method: "post",
            data,
        })
    },
    getUcNewsColumnPage(data){
        return request({
            url: getUrl("column/getUcNewsColumnPage"),
            method: "post",
            data,
        })
    },
}