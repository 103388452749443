import request from "@/utils/request";
function getUrl(url) {
    return "/operate/" + url;
}

export default {
    saveUcTicket(data){
        return request({
            url: getUrl("ticket/saveUcTicket"),
            method: "post",
            data,
        })
    },
    deleteUcTicket(data){
        return request({
            url: getUrl("ticket/deleteUcTicket"),
            method: "post",
            data,
        })
    },
    getUcTicketVo(data){
        return request({
            url: getUrl("ticket/getUcTicketVo"),
            method: "post",
            data,
        })
    },
    getUcTicketPage(data){
        return request({
            url: getUrl("ticket/getUcTicketPage"),
            method: "post",
            data,
        })
    },
    approval(data){
        return request({
            url: getUrl("ticket/approval"),
            method: "post",
            data,
        })
    },
}