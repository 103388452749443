import request from "@/utils/request";
function getUrl(url) {
    return "/operate/" + url;
}

export default {
    addUcAdout(data){
        return request({
            url: getUrl("adout/addUcAdout"),
            method: "post",
            data,
        })
    },
    getUcAdout(data){
        return request({
            url: getUrl("adout/getUcAdout"),
            method: "post",
            data,
        })
    },
    updateUcAdout(data){
        return request({
            url: getUrl("adout/updateUcAdout"),
            method: "post",
            data,
        })
    },
}