import request from "@/utils/request";
function getUrl(url) {
    return "/operate/" + url;
}

export default {
    saveUcPolicyType(data){
        return request({
            url: getUrl("policyType/saveUcPolicyType"),
            method: "post",
            data,
        })
    },
    deleteUcPolicyType(data){
        return request({
            url: getUrl("policyType/deleteUcPolicyType"),
            method: "post",
            data,
        })
    },
    getUcPolicyTypeVO(data){
        return request({
            url: getUrl("policyType/getUcPolicyTypeVO"),
            method: "post",
            data,
        })
    },
    getUcPolicyTypeList(data){
        return request({
            url: getUrl("policyType/getUcPolicyTypeList"),
            method: "post",
            data,
        })
    },
    getUcPolicyTypePage(data){
        return request({
            url: getUrl("policyType/getUcPolicyTypePage"),
            method: "post",
            data,
        })
    },
}