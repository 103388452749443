import Vue from 'vue'
import App from './App.vue'
import router from "./router";
import store from "./store";

import "./permission";

import {
  loading,
  borderBox13,
  borderBox12,
  digitalFlop,
  capsuleChart,
  borderBox8,
  scrollRankingBoard,
} from '@jiaminghi/data-view'

import * as echarts from 'echarts'
Vue.prototype.$echarts = echarts

import { Radio, Button, RadioGroup } from 'element-ui'
import Echart from './components/echart/index.vue'
import ItemWrap from './components/item-wrap/item-wrap.vue'
import Message from './components/message/message.vue'
import Reacquire from './components/reacquire/reacquire.vue'
import Messages from './components/message/message'
import 'vue-easytable/libs/theme-default/index.css'
import '@/assets/css/public.scss'
import '@/assets/css/index.scss'
import "/public/style.css";
import "/public/js/echarts.min.js";

require('./mock/mock') //是否使用mock
import components from "@/components";
components(Vue)

import api from "@/api/index"; // 导入api接口
import QRCode from 'qrcodejs2';
Vue.prototype.$qrCode = QRCode;
import Element from 'element-ui';
import "@/styles/element-variables.scss";

Vue.use(Element, { size: 'small', zIndex: 3000 });

import Loading from './utils/loading';
Vue.use(Loading);

import editor from '@/utils/editorInit'
// 在全局注册 editorInit
Vue.prototype.$editorInit = editor.editorInit

import "@/styles/index.scss";


// 自定义组件
Vue.component('Echart', Echart)
Vue.component('ItemWrap', ItemWrap)
Vue.component('Message', Message)
Vue.component('Reacquire', Reacquire)
Vue.prototype.$Message = Messages
// element组件
Vue.use(Radio)
Vue.use(Button)
Vue.use(RadioGroup)


// datav组件
Vue.use(loading)
Vue.use(borderBox13)
Vue.use(borderBox8)
Vue.use(digitalFlop)
Vue.use(capsuleChart)
Vue.use(borderBox12)
Vue.use(scrollRankingBoard)

//自定义拦截器
import * as filters from './utils/filter.js'

Vue.config.productionTip = false

Vue.prototype.$api = api;

Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})


new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
