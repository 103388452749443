import request from "@/utils/request";
function getUrl(url) {
    return "/operate/" + url;
}

export default {
    saveUcTicketTemplate(data){
        return request({
            url: getUrl("ticketTemplate/saveUcTicketTemplate"),
            method: "post",
            data,
        })
    },
    deleteUcTicketTemplate(data){
        return request({
            url: getUrl("ticketTemplate/deleteUcTicketTemplate"),
            method: "post",
            data,
        })
    },
    getUcTicketTemplateVo(data){
        return request({
            url: getUrl("ticketTemplate/getUcTicketTemplateVo"),
            method: "post",
            data,
        })
    },
    getUcTicketTemplateList(data){
        return request({
            url: getUrl("ticketTemplate/getUcTicketTemplateList"),
            method: "post",
            data,
        })
    },
    getUcTicketTemplatePage(data){
        return request({
            url: getUrl("ticketTemplate/getUcTicketTemplatePage"),
            method: "post",
            data,
        })
    },
}