import request from "@/utils/request";
function getUrl(url) {
    return "/operate/" + url;
}

export default {
    saveUcServiceProducts(data){
        return request({
            url: getUrl("products/saveUcServiceProducts"),
            method: "post",
            data,
        })
    },
    deleteUcServiceProducts(data){
        return request({
            url: getUrl("products/deleteUcServiceProducts"),
            method: "post",
            data,
        })
    },
    getUcServiceProductsVo(data){
        return request({
            url: getUrl("products/getUcServiceProductsVo"),
            method: "post",
            data,
        })
    },
    getUcServiceProductsList(data){
        return request({
            url: getUrl("products/getUcServiceProductsList"),
            method: "post",
            data,
        })
    },
    getUcServiceProductsPage(data){
        return request({
            url: getUrl("products/getUcServiceProductsPage"),
            method: "post",
            data,
        })
    },
}