import request from "@/utils/request";
function getUrl(url) {
    return "/operate/" + url;
}

export default {
    saveUcIndustrialPark(data){
        return request({
            url: getUrl("industrialPark/saveUcIndustrialPark"),
            method: "post",
            data,
        })
    },
    deleteUcIndustrialPark(data){
        return request({
            url: getUrl("industrialPark/deleteUcIndustrialPark"),
            method: "post",
            data,
        })
    },
    getUcIndustrialParkVo(data){
        return request({
            url: getUrl("industrialPark/getUcIndustrialParkVo"),
            method: "post",
            data,
        })
    },
    getUcIndustrialParkPage(data){
        return request({
            url: getUrl("industrialPark/getUcIndustrialParkPage"),
            method: "post",
            data,
        })
    },
}