import request from "@/utils/request";
function getUrl(url) {
    return "/operate/" + url;
}

export default {
    saveUcParkImg(data){
        return request({
            url: getUrl("park/saveUcParkImg"),
            method: "post",
            data,
        })
    },
    deleteUcParkImg(data){
        return request({
            url: getUrl("park/deleteUcParkImg"),
            method: "post",
            data,
        })
    },
    getUcParkImgVo(data){
        return request({
            url: getUrl("park/getUcParkImgVo"),
            method: "post",
            data,
        })
    },
    getUcParkImgPage(data){
        return request({
            url: getUrl("park/getUcParkImgPage"),
            method: "post",
            data,
        })
    },
}