import request from "@/utils/request";
function getUrl(url) {
    return "/operate/" + url;
}

export default {
    addUcStaff(data){
        return request({
            url: getUrl("staff/addUcStaff"),
            method: "post",
            data,
        })
    },
    deleteUcStaff(data){
        return request({
            url: getUrl("staff/deleteUcStaff"),
            method: "post",
            data,
        })
    },
    getUcStaff(data){
        return request({
            url: getUrl("staff/getUcStaff"),
            method: "post",
            data,
        })
    },
    getUcStaffList(data){
        return request({
            url: getUrl("staff/getUcStaffList"),
            method: "post",
            data,
        })
    },
    getUcStaffPage(data){
        return request({
            url: getUrl("staff/getUcStaffPage"),
            method: "post",
            data,
        })
    },
    updateStatus(data){
        return request({
            url: getUrl("staff/updateStatus"),
            method: "post",
            data,
        })
    },
}