import request from "@/utils/request";
function getUrl(url) {
    return "/operate/" + url;
}

export default {
    getUcXinjiangVisitorsDate(data){
        return request({
            url: getUrl("xinjiangVisitors/getUcXinjiangVisitorsDate"),
            method: "post",
            data,
        })
    },
    getUcXinjiangVisitorsByDeviceType(data){
        return request({
            url: getUrl("xinjiangVisitors/getUcXinjiangVisitorsByDeviceType"),
            method: "post",
            data,
        })
    },
    getUcXinjiangVisitorsByVisitorType(data){
        return request({
            url: getUrl("xinjiangVisitors/getUcXinjiangVisitorsByVisitorType"),
            method: "post",
            data,
        })
    },
}
