import request from "@/utils/request";

function getUrl(url) {
    return "/operate/" + url;
}

export default {
    add(data) {
        return request({
            url: getUrl("rotation/saveUcAppRotation"),
            method: "post",
            data,
        })
    },
    update(data) {
        return request({
            url: getUrl("rotation/updateUcAppRotation"),
            method: "post",
            data,
        })
    },
    del(data) {
        return request({
            url: getUrl("rotation/deleteUcAppRotation"),
            method: "post",
            data,
        })
    },
    getInfo(data) {
        return request({
            url: getUrl("rotation/getInfo/"+data),
            method: "get",
        })
    },
    listPage(data) {
        return request({
            url: getUrl("rotation/getUcAppRotationPage"),
            method: "post",
            data,
        })
    },

}
