import request from "@/utils/request";
function getUrl(url) {
    return "/operate/" + url;
}

export default {
    addUcVisitorRegistration(data){
        return request({
            url: getUrl("visitorRegistration/addUcVisitorRegistration"),
            method: "post",
            data,
        })
    },
    deleteUcVisitorRegistration(data){
        return request({
            url: getUrl("visitorRegistration/deleteUcVisitorRegistration"),
            method: "post",
            data,
        })
    },
    getUcVisitorRegistration(data){
        return request({
            url: getUrl("visitorRegistration/getUcVisitorRegistration"),
            method: "post",
            data,
        })
    },
    getUcVisitorRegistrationPage(data){
        return request({
            url: getUrl("visitorRegistration/getUcVisitorRegistrationPage"),
            method: "post",
            data,
        })
    },
    process(data){
        return request({
            url: getUrl("visitorRegistration/process"),
            method: "post",
            data,
        })
    },
}