import request from "@/utils/request";
function getUrl(url) {
    return "/operate/" + url;
}

export default {
    addUcActivityRegistration(data){
        return request({
            url: getUrl("activityRegistration/saveUcActivityRegistration"),
            method: "post",
            data,
        })
    },
    deleteUcActivityRegistration(data){
        return request({
            url: getUrl("activityRegistration/deleteUcActivityRegistration"),
            method: "post",
            data,
        })
    },
    getUcActivityRegistrationVO(data){
        return request({
            url: getUrl("activityRegistration/getUcActivityRegistrationVO"),
            method: "post",
            data,
        })
    },
    getUcActivityRegistrationList(data){
        return request({
            url: getUrl("activityRegistration/getUcActivityRegistrationList"),
            method: "post",
            data,
        })
    },
    getUcActivityRegistrationPage(data){
        return request({
            url: getUrl("activityRegistration/getUcActivityRegistrationPage"),
            method: "post",
            data,
        })
    },
    updateUcActivityRegistration(data){
        return request({
            url: getUrl("activityRegistration/updateUcActivityRegistration"),
            method: "post",
            data,
        })
    },
}