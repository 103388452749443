import request from "@/utils/request";
function getUrl(url) {
    return "/operate/" + url;
}

export default {
    addUcWebsiteInfo(data){
        return request({
            url: getUrl("ucWebsiteInfo/addUcWebsiteInfo"),
            method: "post",
            data,
        })
    },
    getUcWebsiteInfoVO(data){
        return request({
            url: getUrl("ucWebsiteInfo/getUcWebsiteInfoVO"),
            method: "post",
            data,
        })
    },
    updateUcWebsiteInfo(data){
        return request({
            url: getUrl("ucWebsiteInfo/updateUcWebsiteInfo"),
            method: "post",
            data,
        })
    },
}