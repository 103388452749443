import request from "@/utils/request";
function getUrl(url) {
    return "/operate/" + url;
}

export default {
    saveUcPolicySources(data){
        return request({
            url: getUrl("policySources/saveUcPolicySources"),
            method: "post",
            data,
        })
    },
    deleteUcPolicySources(data){
        return request({
            url: getUrl("policySources/deleteUcPolicySources"),
            method: "post",
            data,
        })
    },
    getUcPolicySourcesVO(data){
        return request({
            url: getUrl("policySources/getUcPolicySourcesVO"),
            method: "post",
            data,
        })
    },
    getUcPolicySourcesList(data){
        return request({
            url: getUrl("policySources/getUcPolicySourcesList"),
            method: "post",
            data,
        })
    },
    getUcPolicySourcesPage(data){
        return request({
            url: getUrl("policySources/getUcPolicySourcesPage"),
            method: "post",
            data,
        })
    },
}