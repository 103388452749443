import request from "@/utils/request";
function getUrl(url) {
    return "/operate/" + url;
}

export default {
    saveUcIntendedCustomer(data){
        return request({
            url: getUrl("intendedCustomer/saveUcIntendedCustomer"),
            method: "post",
            data,
        })
    },
    deleteUcIntendedCustomer(data){
        return request({
            url: getUrl("intendedCustomer/deleteUcIntendedCustomer"),
            method: "post",
            data,
        })
    },
    getUcIntendedCustomerVo(data){
        return request({
            url: getUrl("intendedCustomer/getUcIntendedCustomerVo"),
            method: "post",
            data,
        })
    },
    getUcIntendedCustomerPage(data){
        return request({
            url: getUrl("intendedCustomer/getUcIntendedCustomerPage"),
            method: "post",
            data,
        })
    },
    getUcIntendedCustomerConsultPage(data){
        return request({
            url: getUrl("intendedCustomer/getUcIntendedCustomerConsultPage"),
            method: "post",
            data,
        })
    },
    getUcIntendedCustomerConsultVo(data){
        return request({
            url: getUrl("intendedCustomer/getUcIntendedCustomerConsultVo"),
            method: "post",
            data,
        })
    },
    saveUcIntendedCustomerConsult(data){
        return request({
            url: getUrl("intendedCustomer/saveUcIntendedCustomerConsult"),
            method: "post",
            data,
        })
    },
    deleteUcIntendedCustomerConsult(data){
        return request({
            url: getUrl("intendedCustomer/deleteUcIntendedCustomerConsult"),
            method: "post",
            data,
        })
    },
    getUcIntendedCustomerFollowupList(data){
        return request({
            url: getUrl("intendedCustomer/getUcIntendedCustomerFollowupList"),
            method: "post",
            data,
        })
    },
    saveIntendedCustomerFollowup(data){
        return request({
            url: getUrl("intendedCustomer/saveIntendedCustomerFollowup"),
            method: "post",
            data,
        })
    },
    deleteIntendedCustomerFollowup(data){
        return request({
            url: getUrl("intendedCustomer/deleteIntendedCustomerFollowup"),
            method: "post",
            data,
        })
    },
}