import request from "@/utils/request";
function getUrl(url) {
    return "/operate/" + url;
}

export default {
    saveUcServiceOrder(data){
        return request({
            url: getUrl("order/saveUcServiceOrder"),
            method: "post",
            data,
        })
    },
    deleteUcServiceOrder(data){
        return request({
            url: getUrl("order/deleteUcServiceOrder"),
            method: "post",
            data,
        })
    },
    getUcServiceOrderVo(data){
        return request({
            url: getUrl("order/getUcServiceOrderVo"),
            method: "post",
            data,
        })
    },
    getUcServiceOrderPage(data){
        return request({
            url: getUrl("order/getUcServiceOrderPage"),
            method: "post",
            data,
        })
    },
    refundOrder(data){
        return request({
            url: getUrl("order/refundOrder"),
            method: "post",
            data,
        })
    },
}