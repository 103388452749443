import request from "@/utils/request";
function getUrl(url) {
    return "/operate/" + url;
}

export default {
    saveUcNews(data){
        return request({
            url: getUrl("news/saveUcNews"),
            method: "post",
            data,
        })
    },
    deleteUcNews(data){
        return request({
            url: getUrl("news/deleteUcNews"),
            method: "post",
            data,
        })
    },
    batchImport(data){
        return request({
            url: getUrl("news/batchImport"),
            method: "post",
            data,
        })
    },
    getUcNewsVo(data){
        return request({
            url: getUrl("news/getUcNewsVo"),
            method: "post",
            data,
        })
    },
    getUcNewsPage(data){
        return request({
            url: getUrl("news/getUcNewsPage"),
            method: "post",
            data,
        })
    },
}
