import request from "@/utils/request";
function getUrl(url) {
    return "/operate/" + url;
}

export default {
    saveUcServe(data){
        return request({
            url: getUrl("serve/saveUcServe"),
            method: "post",
            data,
        })
    },
    deleteUcServe(data){
        return request({
            url: getUrl("serve/deleteUcServe"),
            method: "post",
            data,
        })
    },
    getUcServeVo(data){
        return request({
            url: getUrl("serve/getUcServeVo"),
            method: "post",
            data,
        })
    },
    getUcServeList(data){
        return request({
            url: getUrl("serve/getUcServeList"),
            method: "post",
            data,
        })
    },
    getUcServePage(data){
        return request({
            url: getUrl("serve/getUcServePage"),
            method: "post",
            data,
        })
    },
}