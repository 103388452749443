import request from "@/utils/request";
function getUrl(url) {
    return "/operate/" + url;
}

export default {
    getUcStaffRepairClockInPage(data){
        return request({
            url: getUrl("repairClockIn/getUcStaffRepairClockInPage"),
            method: "post",
            data,
        })
    },
    getUcStaffRepairClockInList(data){
        return request({
            url: getUrl("repairClockIn/getUcStaffRepairClockInList"),
            method: "post",
            data,
        })
    },
    cardReplacement(data){
        return request({
            url: getUrl("repairClockIn/cardReplacement"),
            method: "post",
            data,
        })
    },
    cancels(data){
        return request({
            url: getUrl("repairClockIn/cancel"),
            method: "post",
            data,
        })
    },
}
