import request from "@/utils/request";
function getUrl(url) {
    return "/operate/" + url;
}
const uploadPic = getUrl("oss/uploadPic")

const uploadFile = getUrl("oss/uploadFile")
const ossUploadFile = getUrl("oss/uploadFile")

export {uploadPic,uploadFile,ossUploadFile}
