import request from "@/utils/request";
function getUrl(url) {
    return "/operate/" + url;
}

export default {
    getWeeklyData(data){
        return request({
            url: getUrl("home/getWeeklyData"),
            method: "post",
            data,
        })
    },
    getWeeklySales(data){
        return request({
            url: getUrl("home/getWeeklySales"),
            method: "post",
            data,
        })
    },

}
