import request from "@/utils/request";
function getUrl(url) {
    return "/operate/" + url;
}

export function uploadPics(data) {
    return request({
        url: getUrl('oss/uploadPic'),
        headers: {
            'Content-Type':'multipart/form-data'
        },
        method: 'post',
        data: data
    })
}

export function uploadFile(data) {
    return request({
        url: getUrl('oss/uploadFile'),
        headers: {
            'Content-Type':'multipart/form-data'
        },
        method: 'post',
        data: data
    })
}