import request from "@/utils/request";
function getUrl(url) {
    return "/operate/" + url;
}

export default {
    addUcEnterprise(data){
        return request({
            url: getUrl("enterprise/addUcEnterprise"),
            method: "post",
            data,
        })
    },
    updateStatus(data){
        return request({
            url: getUrl("enterprise/updateStatus"),
            method: "post",
            data,
        })
    },
    deleteUcEnterprise(data){
        return request({
            url: getUrl("enterprise/deleteUcEnterprise"),
            method: "post",
            data,
        })
    },
    getUcEnterprise(data){
        return request({
            url: getUrl("enterprise/getUcEnterprise"),
            method: "post",
            data,
        })
    },
    getUcEnterprisePage(data){
        return request({
            url: getUrl("enterprise/getUcEnterprisePage"),
            method: "post",
            data,
        })
    },
    getUcEnterpriseList(data){
        return request({
            url: getUrl("enterprise/getUcEnterpriseList"),
            method: "post",
            data,
        })
    },
}